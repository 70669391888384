<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-05-16 18:23:25
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-08-14 15:03:03
 * @FilePath: /mediatom-web/src/components/VideoPreview/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="video">
    <div class="video-content" id="videoScroll" ref="videoScrollBar">
      <div class="videoCard" v-for="(item, index) in videoList" :key="index">
        <div style="width: 100%" @mouseenter="startPlay(item, index)" @mouseleave="move(item, index)">
          <div :ref="`video${index}`"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Player from 'xgplayer'
// 文档地址
// https://v2.h5player.bytedance.com/gettingStarted/#%E4%BD%BF%E7%94%A8%E7%AE%80%E6%98%93%E7%89%88%E6%9C%AC
export default {
  name: 'VideoPreview',
  props: {
    videoList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dom: [],
      timeId: ''
    }
  },
  created () {
    this.timeId = setTimeout(() => {
      if (this.videoList.length) {
        this.videoList.forEach((item, index) => {
          this.init(item, index)
        })
      } else {
        this.dom.length = 0
      }
    }, 50)
  },
  methods: {
    init (data, index) {
      this.dom[index] = new Player({
        el: this.$refs['video' + index][0],
        // 地址
        url: data.url,
        videoInit: true,
        // 封面图
        poster: data.poster,
        lang: 'zh-cn',
        controls: false,
        playbackRate: [0.5, 0.75, 1, 1.5, 2],
        whitelist: [''],
        ignores: ['play'],
        fluid: false,
        width: '100%'
      })
    },
    // 移入播放视频
    startPlay (data, index) {
      // this.$nextTick(() => {
      //   this.dom[index].play()
      // })
    },
    // 移除暂停视频
    move (data, index) {
      if (this.timeId) clearTimeout(this.timeId)
      this.timeId = setTimeout(() => {
        this.dom[index].pause()
      }, 0)
    }
  }
}
</script>

<style scoped lang="less">
.videoCard {
  width: 100%;
  margin: 0 20px 0px 0;
  float: left;
}
.title {
  width: 100%;
  color: #000;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  font-weight: 600;
  padding-left: 10px;
}
</style>
